import Menu from "../components/Menu";
import Footer from "../components/Footer";

const Layout = ({ props, children }) => {

  return (
    <>
      <Menu />
      <main>{children}</main>
      <Footer props={props} />
    </>
  );
};

export default Layout;